import { Stack, Text } from "@chakra-ui/layout";
import { lazy, Suspense } from "react";
// import { Route, Routes, BrowserRouter as Router } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "./utils/constants";
import {
  useNavigate,
  Redirect,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import "./App.css";
import NotAvailablePage from "./pages/dashboard/pages/NotAvailablePage";
const AddDetails = lazy(() => import("./pages/auth/pages/AddDetails"));
const Auth = lazy(() => import("./pages/auth/pages/Auth"));
const EnterOTP = lazy(() => import("./pages/auth/pages/EnterOTP"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Homepage = lazy(() => import("./pages/Homepage"));
const Properties = lazy(() => import("./pages/Properties"));

const FallbackScreen = () => (
  <Stack h="100vh" w="100vw" justify="center" align="center">
    <Text>Loading...</Text>
  </Stack>
);

function App() {
  const navigate = useNavigate();
  const GETHOTELIMAGESURL = "/user/groupBranding";

  // Setting the status from the local storage
  const [whiteLabelStatus, setWhiteLabelStatus] = useState("enabled");

  useEffect(() => {
    axios
      .get(
        baseUrl +
          GETHOTELIMAGESURL +
          "?hostUrl=" +
          `${window.location.hostname}`
      )
      .then((resp) => {
        localStorage.setItem("logo", resp?.data?.logoUrl);
        if (resp?.data?.status !== undefined) {
          localStorage.setItem("status", resp?.data?.status);
        } else {
          localStorage.setItem("status", "DISABLED");
        }

        localStorage.setItem("WhiteLabelData", JSON.stringify(resp?.data));
        setLoader(resp?.data?.status);
        setWhiteLabelStatus(resp?.data?.status);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const [loader, setLoader] = useState(null);

  // useEffect(() => {
  //   setWhiteLabelStatus(localStorage.getItem("status"));
  // }, []);

  // useEffect(() => {
  //   whiteLabelStatus === "ENABLED" ? navigate("/auth") : navigate("/");
  // }, [whiteLabelStatus]);

  return (
    <>
      <Suspense fallback={<FallbackScreen />}>
        {/* {loader && whiteLabelStatus !== "Anything" && ( */}
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                {/* <Homepage /> */}
                <NotAvailablePage />
              </PrivateRoute>
            }
          />
          <Route path="auth" element={/*<Auth />*/ <NotAvailablePage />} />
          <Route
            path="search"
            element={/*<Properties />*/ <NotAvailablePage />}
          />
          <Route
            path="auth/:type/sendotp"
            element={/*<EnterOTP />*/ <NotAvailablePage />}
          />
          <Route
            path="auth/add_details"
            element={/*<AddDetails />*/ <NotAvailablePage />}
          />
          <Route
            path="dashboard"
            element={/*<Dashboard />*/ <NotAvailablePage />}
          />
        </Routes>
        {/* )} */}
      </Suspense>
    </>
  );
}

export default App;
