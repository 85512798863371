import { Heading, Image, Stack } from "@chakra-ui/react";
import noData from "../../../assets/nodata.svg";

const NotAvailablePage = () => {
  return (
    <Stack
      alignItems="center"
      alignSelf="center"
      justifyContent="center"
      height="100%"
      width={"100%"}
      spacing="10"
      marginTop={"200"}
    >
      <Image
        objectFit="contain"
        src={noData}
        height={{ base: "100", md: "60" }}
        alt="Mumbai"
        borderRadius="lg"
      />
      <Heading
        as="h6"
        size="md"
        color="blackAlpha.600"
        fontWeight="bold"
        marginBottom={"0"}
      >
        Account disabled due to no payment.
      </Heading>
      <Heading
        as="h6"
        size="md"
        color="blackAlpha.600"
        fontWeight="bold"
        style={{ marginTop: "6px" }}
      >
        Contact support@stayflexi.com for more information
      </Heading>
    </Stack>
  );
};
export default NotAvailablePage;
