export const initialState = {
  user: localStorage.getItem("otaUser") ? JSON.parse(localStorage.getItem("otaUser")) : false,
  email: localStorage.getItem("otaUserEmail") ? localStorage.getItem("otaUserEmail") : "",
  travelAgentDetails : {},
  travelAgentImages : {},
  token: localStorage.getItem("otaUserToken") ? localStorage.getItem("otaUserToken") : "",
  isTravelAgent: localStorage.getItem("otaUserIsTravelAgent")
    ? JSON.parse(localStorage.getItem("otaUserIsTravelAgent"))
    : false,
  center: {
    lat: 12.9716,
    lng: 77.5946,
  },
  properties: [],
  activeHotelInMap : null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      localStorage.setItem("otaUser", action.user);
      return {
        ...state,
        user: action.user,
      };

    case "SET_USER_EMAIL":
      localStorage.setItem("otaUserEmail", action.email);
      return {
        ...state,
        email: action.email,
      };
    case "SET_OTA_DATA":
      localStorage.setItem("travelAgentDetails", JSON.stringify(action.travelAgentDetails));
      return {
        ...state,
        travelAgentDetails: action.travelAgentDetails,
      };

    case "SET_OTA_IMAGES":
    localStorage.setItem("travelAgentImages", JSON.stringify(action.travelAgentImages));
    return {
      ...state,
      travelAgentImages: action.travelAgentImages,
    };

    case "SET_TOKEN":
      localStorage.setItem("otaUserToken", action.token);

      return {
        ...state,
        token: action.token,
      };

    case "SET_IS_USER_TRAVEL_AGENT":
      localStorage.setItem("otaUserIsTravelAgent", action.isTravelAgent);

      return {
        ...state,
        isTravelAgent: action.isTravelAgent,
      };

    case "SET_CENTER":
      return {
        ...state,
        center: action.center,
      };
    case "SET_PROPERTIES":
      return {
        ...state,
        properties: action.properties,
      };
      case "SET_CURRENT_HOTEL_ON_MAP":
      return {
        ...state,
        activeHotelInMap: action.activeHotelInMap,
      };
    default:
      return state;
  }
};

export default reducer;
