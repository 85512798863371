// import dotenv from "dotenv"
// dotenv.config()
export const baseUrl = process.env.REACT_APP_API_URL;
export const baseUrlDjango = process.env.REACT_APP_API_DJANGO_URL;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const razorPayKey = process.env.REACT_APP_RAZORPAY_PUBLIC_KEY;
export const ENV = process.env.REACT_APP_ENV;
export const gmapsAPIKey = process.env.REACT_APP_GMAPS_API_KEY;
export const beURL = process.env.REACT_APP_BE_URL;
export const joinSF = process.env.REACT_APP_JOIN_SF_URL;
export const pmsSFUrl = process.env.REACT_PMS_URL;
export const stayflexiGreen = "#34B3A3";
export const mtdcDash = "#E84E0F";
export const mtdcDashgradient =
  "linear-gradient(360deg, rgba(232,129,15,0.9766500350140056) 22%, rgba(232,100,15,1) 58%)";
export const mtdcDashgradientInv =
  "linear-gradient(180deg, rgba(232,129,15,0.9766500350140056) 58%, rgba(232,100,15,1) 22%)";
export const bookingStatusMap = {
  CONFIRMED: "CONFIRMED",
  ADMIN_CONFIRMED: "CHECKED IN",
  CANCELLED: "CANCELLED",
  NO_SHOW: "NO SHOW",
  CHECKED_OUT: "CHECKED OUT",
  PENDING: "PENDING",
  ON_HOLD: "ON HOLD",
  BOOKING_ENQUIRY: "ENQUIRY",
};
