import React from "react";
import { Navigate, Route } from "react-router-dom";

function PrivateRoute({ children }) {
  console.log(
    "getting into the private route",
    localStorage.getItem("validated")
  );
  const isWhiteLabel =
    localStorage.getItem("status") &&
    localStorage.getItem("status").toLowerCase() === "enabled"
      ? true
      : false;
  if (localStorage.getItem("validated")) return children;
  return !isWhiteLabel ? children : <Navigate to="/auth" />;
}

export default PrivateRoute;
